<template>
    <div class="overlay">
        <div class="dialog">
            <button @click="$emit('close')" class="close">
                <font-awesome-icon icon="close" size="2x" />
            </button>
            <main>
                <slot></slot>
            </main>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalWrapper",
    emits: ['close'],
}
</script>

<style lang="scss" scoped>
@import 'animate-css-mixins/animate.scss';

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--opacity-50);
    transform: translate3d(0, 0, 10px);
    display: flex;
    place-items: center;
    justify-content: center;
}

.dialog {
    background: var(--modal-content-bg);
    width: calc(100% - 8px);
    max-width: 516px;
    max-height: calc(100% - 8px);
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--color-tone-4);
    overflow: auto;
    display: flex;
}

.close {
    color: white;
    background: transparent;
    appearance: none;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    aspect-ratio: 1 / 1;
    display: grid;
    place-items: center;

    position: absolute;
    right: 4px;
    top: 4px;

    cursor: pointer;

    &:hover {
        background: rgba(255, 255, 255, .35);
    }
}

main {
    width: 100%;
    overflow: auto;
    padding: 22px 16px;

    @media screen and (max-width: 560px) {
        padding: 16px;
    }
}
</style>