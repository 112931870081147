<template>
    <div class="tile" :class="evaluation">
        {{ letter }}
    </div>
</template>
<script>
export default {
    props: {
        letter: {
            type: String,
            default: ''
        },
        evaluation: {
            type: String,
            default: ''
        },
    }
}
</script>

<style lang="scss" scoped>
@import 'animate-css-mixins/animate.scss';

div {
    font-size: 2rem;
    min-width: min(7vh, 15vw);
    min-height: min(6vh, 14vw);
    aspect-ratio: 1 / 1;
    display: grid;
    place-items: center;
    text-transform: uppercase;
    font-weight: bold;
    background-color: var(--color-tone-7);
    outline: 2px solid var(--color-tone-3);
    outline-offset: -2px;
    color: var(--color-tone-1);
    transform: translate3d(0, 0, 1px);
    margin: 2px;

    @media screen and (max-height: 560px) {
        font-size: 3.5vh;
    }


    &:empty {
        outline-color: var(--color-tone-4);
    }

    &:not(:empty) {
        @include animate__bounceIn;
        animation-duration: 200ms;
    }

    &.target {
        outline-style: dashed;
        outline-color: var(--color-tone-2);
    }

    &.present {
        background: var(--color-present);
        outline: none;
        @include animate__flipInX;
        animation-duration: 1000ms;
    }

    &.correct {
        background: var(--color-correct);
        outline: none;
        @include animate__flipInX;
        animation-duration: 600ms;
    }

    &.absent {
        background: var(--color-absent);
        outline: none;
        @include animate__flipInX;
        animation-duration: 600ms;
    }
}
</style>