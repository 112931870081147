<template>
    <template v-if="game">
        <div class="game">
            <header>
                <div class="title" v-if="game.id">Wordlunlimited #{{ game.id + 1 }}</div>
                <div class="title" v-else>Wordlunlimited
                    <font-awesome-icon icon="random" size="xs" />
                </div>
                <div @click="instructions = true" class="close">
                    <font-awesome-icon icon="fa-regular fa-circle-question" size="xl" />
                </div>
            </header>
            <main>
                <ConfettiVue v-if="game.won" />
                <div class="board-container">
                    <div class="grid">
                        <div class="grid-row" :class="{
                            invalid: row_id == target.row && invalid,
                            won: row_id == target.row && game.won
                        }" v-for="(gridRow, row_id) in game.grid" :key="'row-' + row_id">
                            <game-tile v-for="(tile, tile_id) in gridRow" :key="'tile-' + row_id + tile_id"
                                :class="[{ target: tile == targetTile }]" :letter="tile.letter"
                                :evaluation="tile.result" />
                        </div>
                    </div>
                    <div v-if="game.lose" class="secrete">{{ game.secrete }}</div>
                </div>
                <div v-if="game.won || game.lose" class="game-over">
                    <section class="statistics">
                        <h1>Statistics</h1>
                        <div class="statistics-wrapper">
                            <div class="statistic-container">
                                <div class="statistic">{{ stats.gamesPlayed }}</div>
                                <div class="label">Played</div>
                            </div>

                            <div class="statistic-container">
                                <div class="statistic">{{ stats.winPercentage }}</div>
                                <div class="label">Win %</div>
                            </div>

                            <div class="statistic-container">
                                <div class="statistic">{{ stats.currentStreak }}</div>
                                <div class="label">Current Streak</div>
                            </div>

                            <div class="statistic-container">
                                <div class="statistic">{{ stats.maxStreak }}</div>
                                <div class="label">Max Streak</div>
                            </div>
                        </div>
                    </section>
                    <div class="action">
                        <button class="share" @click="share = true">
                            Share
                            <font-awesome-icon icon="fa-solid fa-share-nodes" />
                        </button>
                        <button class="newGame" @click="newGame">{{ game.won ? 'Play' : 'Try' }} again</button>
                    </div>
                </div>
                <div v-else class="keyboard">
                    <div class="row">
                        <button :class="keyCode[keyboard.q]" @click="gameInput('q')">q</button>
                        <button :class="keyCode[keyboard.w]" @click="gameInput('w')">w</button>
                        <button :class="keyCode[keyboard.e]" @click="gameInput('e')">e</button>
                        <button :class="keyCode[keyboard.r]" @click="gameInput('r')">r</button>
                        <button :class="keyCode[keyboard.t]" @click="gameInput('t')">t</button>
                        <button :class="keyCode[keyboard.y]" @click="gameInput('y')">y</button>
                        <button :class="keyCode[keyboard.u]" @click="gameInput('u')">u</button>
                        <button :class="keyCode[keyboard.i]" @click="gameInput('i')">i</button>
                        <button :class="keyCode[keyboard.o]" @click="gameInput('o')">o</button>
                        <button :class="keyCode[keyboard.p]" @click="gameInput('p')">p</button>
                    </div>
                    <div class="row">
                        <button :class="keyCode[keyboard.a]" @click="gameInput('a')">a</button>
                        <button :class="keyCode[keyboard.s]" @click="gameInput('s')">s</button>
                        <button :class="keyCode[keyboard.d]" @click="gameInput('d')">d</button>
                        <button :class="keyCode[keyboard.f]" @click="gameInput('f')">f</button>
                        <button :class="keyCode[keyboard.g]" @click="gameInput('g')">g</button>
                        <button :class="keyCode[keyboard.h]" @click="gameInput('h')">h</button>
                        <button :class="keyCode[keyboard.j]" @click="gameInput('j')">j</button>
                        <button :class="keyCode[keyboard.k]" @click="gameInput('k')">k</button>
                        <button :class="keyCode[keyboard.l]" @click="gameInput('l')">l</button>
                    </div>
                    <div class="row">
                        <button @click="gameInput('enter')" :disabled="entry.length != 5"
                            class="flex-15 enter">enter</button>
                        <button :class="keyCode[keyboard.z]" @click="gameInput('z')">z</button>
                        <button :class="keyCode[keyboard.x]" @click="gameInput('x')">x</button>
                        <button :class="keyCode[keyboard.c]" @click="gameInput('c')">c</button>
                        <button :class="keyCode[keyboard.v]" @click="gameInput('v')">v</button>
                        <button :class="keyCode[keyboard.b]" @click="gameInput('b')">b</button>
                        <button :class="keyCode[keyboard.n]" @click="gameInput('n')">n</button>
                        <button :class="keyCode[keyboard.m]" @click="gameInput('m')">m</button>
                        <button @click="gameInput('delete')" class="flex-15">
                            <font-awesome-icon icon="delete-left" size="lg" />
                        </button>
                    </div>
                </div>
            </main>
        </div>
    </template>
    <ModalWrapperVue v-if="instructions" @close="instructions = false">
        <div class="instructions">
            <p>Guess the <strong>WORDL</strong> in six tries.</p>
            <p>Each guess must be a valid five-letter word. Hit the enter button to submit.</p>
            <p>After each guess, the color of the tiles will change to show how close your guess was to the word.</p>
            <div class="examples">
                <p><strong>Examples</strong></p>
                <div class="example">
                    <div class="row">
                        <game-tile letter="w" evaluation="correct" reveal=""></game-tile>
                        <game-tile letter="e"></game-tile>
                        <game-tile letter="a"></game-tile>
                        <game-tile letter="r"></game-tile>
                        <game-tile letter="y"></game-tile>
                    </div>
                    <p>The letter <strong>W</strong> is in the word and in the correct spot.</p>
                </div>
                <div class="example">
                    <div class="row">
                        <game-tile letter="p" />
                        <game-tile letter="i" evaluation="present" />
                        <game-tile letter="l" />
                        <game-tile letter="l" />
                        <game-tile letter="s" />
                    </div>
                    <p>The letter <strong>I</strong> is in the word but in the wrong spot.</p>
                </div>
                <div class="example">
                    <div class="row">
                        <game-tile letter="v" />
                        <game-tile letter="a" />
                        <game-tile letter="g" />
                        <game-tile letter="u" evaluation="absent" />
                        <game-tile letter="e" />
                    </div>
                    <p>The letter <strong>U</strong> is not in the word in any spot.</p>
                </div>
            </div>
            <p><s>A new WORDL will be available each day!</s></p>
            <p><strong class="animate__tada">Play as much as you want!</strong></p>
        </div>
    </ModalWrapperVue>
    <ModalWrapperVue v-if="share" @close="share = false">
        <div class="share-container">
            <ShareNetwork network="whatsapp" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-brands fa-whatsapp" />
                WhatsApp
            </ShareNetwork>
            <ShareNetwork network="twitter" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-brands fa-twitter" />
                Twitter
            </ShareNetwork>
            <ShareNetwork network="linkedin" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-brands fa-linkedin" />
                LinkedIn
            </ShareNetwork>

            <ShareNetwork network="facebook" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-brands fa-facebook" />
                Facebook
            </ShareNetwork>

            <ShareNetwork network="reddit" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-brands fa-reddit" />
                Reddit
            </ShareNetwork>

            <ShareNetwork network="telegram" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-brands fa-telegram" />
                Telegram
            </ShareNetwork>

            <ShareNetwork network="email" :title="socialShare.text" :url="socialShare.url" class="share-link">
                <font-awesome-icon icon="fa-solid fa-envelope" />
                Email
            </ShareNetwork>
        </div>
    </ModalWrapperVue>
</template>

<script>
import logEvents from './firebase'
import { secreteWords, validWords } from '@/words'
import ModalWrapperVue from './components/ModalWrapper.vue'
import GameTile from './components/GameTile'
import ConfettiVue from './components/Confetti'

export default {
    name: "DailyView",
    components: {
        ModalWrapperVue,
        GameTile,
        ConfettiVue
    },
    data() {
        return {
            socialShare: {
                text: "Play Wordl Unlimited. The world's most addictive game.",
                title: window.document.title,
                url: window.document.location.href
            },
            stats: null,
            instructions: false,
            share: false,
            menu: false,
            invalid: false,
            game: null,
            keyCode: [
                '',
                'absent',
                'present',
                'correct'
            ]
        }
    },
    computed: {
        target() {
            return this.game?.target ?? {}
        },
        targetTile() {
            const { row, column } = this.target
            return this.game.grid[row]?.[column]
        },
        entry() {
            const { grid, target } = this.game
            return grid[target.row]?.map(({ letter }) => letter).join('') ?? ''
        },
        keyboard() {
            return this.game?.keyboard ?? {}
        }
    },
    watch: {
        share(val) {
            if (val) logEvents('game_shared')
        }
    },
    mounted() {
        this.initStats()
        this.loadGame()

        if (localStorage.getItem('instructions') != 'true') {
            this.instructions = true
            localStorage.setItem('instructions', 'true')
        }

        window.addEventListener("keyup", input => {
            if (input.repeat) return
            this.gameInput(input.key.toLowerCase())
        })
    },
    methods: {
        initStats() {
            try {
                const stats = JSON.parse(localStorage.getItem('stats'))
                if (!stats || typeof stats !== 'object') throw false
                this.stats = stats
            } catch {
                this.stats = {
                    "currentStreak": 0,
                    "maxStreak": 0,
                    "guesses": {
                        "1": 0,
                        "2": 0,
                        "3": 0,
                        "4": 0,
                        "5": 0,
                        "6": 0,
                        "fail": 0
                    },
                    "winPercentage": 0,
                    "gamesPlayed": 0,
                    "gamesWon": 0,
                    "averageGuesses": null
                }
            }
        },
        updateStats() {
            this.stats.gamesPlayed++
            if (this.game.won) {
                this.stats.currentStreak++
                this.stats.guesses[this.game.target.row]++
                this.stats.gamesWon++
                if (this.stats.currentStreak > this.stats.maxStreak) {
                    this.stats.maxStreak = this.stats.currentStreak
                }
            } else {
                this.stats.currentStreak = 0
                this.stats.guesses.fail++
            }

            this.stats.winPercentage = Math.round(this.stats.gamesWon / this.stats.gamesPlayed * 100)
            localStorage.setItem('stats', JSON.stringify(this.stats))
        },
        loadGame() {
            try {
                const game = JSON.parse(localStorage.getItem('game'))
                if (!game || typeof game !== 'object') throw false
                this.game = game
            } catch {
                this.newGame()
            }
        },
        newGame() {
            const game = this.game = {
                id: null,
                lose: false,
                won: false,
                secrete: "",
                grid: [
                    [
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        }
                    ],
                    [
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        }
                    ],
                    [
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        }
                    ],
                    [
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        }
                    ],
                    [
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        }
                    ],
                    [
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        },
                        {
                            letter: "",
                            result: "",
                        }
                    ]
                ],
                target: {
                    row: 0,
                    column: 0
                },
                keyboard: {
                    q: 0,
                    w: 0,
                    e: 0,
                    r: 0,
                    t: 0,
                    y: 0,
                    u: 0,
                    i: 0,
                    o: 0,
                    p: 0,
                    a: 0,
                    s: 0,
                    d: 0,
                    f: 0,
                    g: 0,
                    h: 0,
                    j: 0,
                    k: 0,
                    l: 0,
                    z: 0,
                    x: 0,
                    c: 0,
                    v: 0,
                    b: 0,
                    n: 0,
                    m: 0,
                }
            }

            const date = Math.floor((new Date()).getTime() / (1000 * 3600 * 24))

            if (localStorage.getItem('dailyChallenge') == date) {
                game.secrete = secreteWords[Math.floor(Math.random() * secreteWords.length)];
            } else {
                localStorage.setItem('dailyChallenge', date)

                const startDate = Math.floor((new Date('06/15/2022')).getTime() / (1000 * 3600 * 24))

                game.id = (date - startDate) % secreteWords.length
                game.secrete = secreteWords[game.id]
            }
            this.saveGame()
        },
        saveGame() {
            localStorage.setItem('game', JSON.stringify(this.game))
        },
        gameInput(key) {
            if (this.game.won || this.game.lose || typeof key !== 'string') return

            const action = key.toLowerCase()
            if (action.match(/[a-z]/i) && action.length == 1) this.setLetter(action)

            if (action == "enter") this.submit()

            if (action == "delete" || action == "backspace") this.backspace()
        },
        setLetter(letter) {
            if (!this.targetTile) return
            this.targetTile.letter = letter
            this.target.column++
        },
        submit() {
            if (!secreteWords.includes(this.entry) && !validWords.includes(this.entry)) {
                this.invalid = true

                setTimeout(() => {
                    this.invalid = false
                }, 2000);

                return
            }

            const secrete = this.game.secrete.split('')
            const gridRow = this.game.grid[this.target.row]

            gridRow.forEach((tile, index) => {
                if (secrete[index] != tile.letter) return

                tile.result = 'correct'
                secrete[index] = '_'
                this.updateKeyboard(tile.letter, 'correct')
            });

            {
                const secrete_set = new Set(secrete)
                if (secrete_set.size == 1 && secrete_set.has('_')) {
                    this.game.won = true
                    logEvents('game_won')
                    this.saveGame()
                    this.updateStats()
                    return
                }
            }

            gridRow.forEach((tile) => {
                if (!!tile.result) return
                const index = secrete.indexOf(tile.letter)
                if (index > -1) {
                    tile.result = 'present'
                    secrete[index] = '_'
                } else {
                    tile.result = 'absent'
                }
                this.updateKeyboard(tile.letter, tile.result)
            });
            this.game.target.row++
            this.game.target.column = 0

            if (this.game.target.row > 5) {
                this.game.lose = true
                logEvents('game_lost')
                this.updateStats()
            }

            this.saveGame()
        },
        backspace() {
            if (this.game.target.column == 0) return
            this.game.target.column--
            this.targetTile.letter = ""
        },
        updateKeyboard(letter, result) {
            this.game.keyboard[letter] = Math.max({
                correct: 3,
                present: 2,
                absent: 1
            }[result] || 0, this.game.keyboard[letter] || 0)
        }
    }
}
</script>

<style lang="scss">
:root {
    --green: #6aaa64;
    --darkendGreen: #538d4e;
    --yellow: #c9b458;
    --darkendYellow: #b59f3b;
    --lightGray: #d8d8d8;
    --gray: #86888a;
    --darkGray: #939598;
    --white: #fff;
    --black: #212121;

    --color-tone-1: #ffffff;
    --color-tone-2: #818384;
    --color-tone-3: #565758;
    --color-tone-4: #3a3a3c;
    --color-tone-5: #272729;
    --color-tone-6: #1a1a1b;
    --color-tone-7: #121213;
    --color-nav-hover: #2f2f31;
    --opacity-50: rgba(0, 0, 0, 0.5);


    --color-present: var(--darkendYellow);
    --color-correct: var(--darkendGreen);
    --color-absent: var(--color-tone-4);
    --tile-text-color: var(--color-tone-1);
    --key-text-color: var(--color-tone-1);
    --key-evaluated-text-color: var(--color-tone-1);
    --key-bg: var(--color-tone-2);
    --key-bg-present: var(--color-present);
    --key-bg-correct: var(--color-correct);
    --key-bg-absent: var(--color-absent);
    --modal-content-bg: var(--color-tone-7);
}
</style>

<style lang="scss" scoped>
@import 'animate-css-mixins/animate.scss';
@import '@/assets/game.scss';

.instructions {
    font-size: 14px;

    .tile {
        width: 24px;
        display: inline-grid;
        margin-right: 4px;
        min-width: min(6vh, 12vw);
    }

    .examples {
        border-bottom: 1px solid var(--color-tone-4);
        border-top: 1px solid var(--color-tone-4);
    }

    .animate__tada {
        display: inline-block;
        @include animate__tada;
        animation-duration: 800ms;
        animation-delay: 8s;
        animation-iteration-count: 12;
    }
}

.secrete {
    text-align: center;
    font-size: 2rem;
}

.result {
    text-align: center;
    font-size: 1.1rem;
    padding: 12px;
    border-bottom: 1px solid var(--color-tone-4);
}

.share-link {
    display: flex;
    gap: 8px;
    color: white;
    font-size: 1.3rem;
    border: 1px solid var(--color-tone-4);
    padding: 8px 16px;
    align-items: center;
    margin: 8px;
    border-radius: 4px;
    text-decoration: none;
}
</style>