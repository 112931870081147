
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC8blfiR5z7hVX2oYtMF-1cki-k9to4KWI",
    authDomain: "wordlunlimited.firebaseapp.com",
    projectId: "wordlunlimited",
    storageBucket: "wordlunlimited.appspot.com",
    messagingSenderId: "787451656297",
    appId: "1:787451656297:web:37c3174c85ab7983214af8",
    measurementId: "G-GG4BLE0MKT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received')

export default (params) => logEvent(analytics, ...params)